import React, { useState } from 'react';
import { Box, Typography, IconButton, Container, Button, Stepper, Step } from '@material-ui/core'
import StepLabel from '@material-ui/core/StepLabel';
import { Page, Layout, Addresses, RazarPayComp, CheckoutReview, ErrorComp } from "../components";
import { useCartData, useAuthData, useCheckoutData } from '../helpers';
// import { Router, useMatch } from '@reach/router';
import { BsArrowLeft } from 'react-icons/bs';
import { Link } from "gatsby";

function getSteps() {
  return ['Billing Address', 'Shipping Address', 'Payment'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Box paddingX={2} paddingY={3}><Addresses type='billing' /></Box>;
    case 1:
      return <Box paddingX={2} paddingY={3}><Addresses type='shipping' /></Box>;
    case 2:
      return <Box paddingX={2} paddingY={3}>
        <CheckoutReview />
        <RazarPayComp />
      </Box>;
    default:
      return 'Unknown step';
  }
}

const Checkout = ({ classes }) => {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const cartData = useCartData();
  const authData = useAuthData();
  const checkoutData = useCheckoutData();
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Layout>
      {/* <Page> */}
      <Box paddingY={5} className='checkoutPage'>
        {authData?.isLoggedIn ? <>
          <Container>
            <Typography variant='h4'>
              <Box display='flex' alignItems='center' justifyContent='space-between'>
                Checkout
                <Link to='/cart'>
                  <Button style={{ color: '#000', borderRadius: '25px' }}>
                    <BsArrowLeft style={{ fontSize: '20px' }} /><Box width='8px' /> Go To Cart
                  </Button>
                </Link>
              </Box>
            </Typography>
            <Box>
              {cartData?.cartDataList.length > 0 ? <>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
                <div>
                  {activeStep === steps.length ? (
                    <div>
                      <Typography>
                        All steps completed
                      </Typography>
                      <Button onClick={handleReset}>
                        Reset
                      </Button>
                    </div>
                  ) : (
                    <div>
                      <Typography>{getStepContent(activeStep)}</Typography>
                      <div>
                        <Button disabled={activeStep === 0} onClick={handleBack}>
                          Back
                        </Button>
                        {activeStep === steps.length - 1 ? '' : <>
                          {activeStep === 0 && <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!checkoutData?.selectedBillingAddress}
                          >
                            {'Next'}
                          </Button>}
                          {activeStep === 1 && <Button
                            variant="contained"
                            color="primary"
                            onClick={handleNext}
                            disabled={!checkoutData?.selectedShippingAddress}
                          >
                            {'Next'}
                          </Button>}
                        </>}

                      </div>
                    </div>
                  )}
                </div>
              </> : <><Box paddingTop={5}>Your cart is empty!</Box></>}
            </Box>
          </Container>
        </> : <ErrorComp />}
      </Box>
      {/* </Page> */}
    </Layout>
  )
}

export default Checkout
